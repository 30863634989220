import {PageContainerDPC} from '@dpc-fe/shared';
import React from 'react';
import {graphql} from "gatsby";
import {prop} from "../functions/functions";

const NotFoundPage = ({data}) => {

    const breadcrumbItem = {
        name: '404',
        slug: 'it/404',
        active: true
    }

    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          menuItems={data.menu.edges}
                          title={'404'}
                          env={process.env.ENV}
                          lang={'it'}
                          breadcrumbItem={breadcrumbItem}
                          minisiti={prop}
                          menuFooterItems={data.menuFooter.edges}
        >
            <section className="container article-card-simple">
                <div className="px-custom">
                    <div className="text-center py-5">
                        <h1>Pagina non trovata</h1>
                        <p>Il contenuto che cercavi è stato spostato o eliminato.</p>
                        <p>Torna alla <a href="/">homepage</a> del sito oppure utilizza la ricerca.</p>
                    </div>
                </div>
            </section>
        </PageContainerDPC>
    );
};

export default NotFoundPage;

export const query = graphql`
  {
    menu: allTaxonomyTermMenuPortale {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__notizia {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_senza_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__sezione_con_anticipazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_approfondimenti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_podcast {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_notizie_comunicati_stampa {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_pubblicazioni {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_normative {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_bandi_di_gara_e_contratti {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_foto_e_video {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_volontariato {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_rischi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                        drupal_internal__nid
                    }
                    ... on node__homepage_minisito_ser_nazionale {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_relazioni {
                        fields {
                                slug
                           }
                           relationships {
                                field_sottodominio {
                                    name
                                }
                           }
                           drupal_internal__nid
                    }
                    ... on node__homepage_minisito_mappe {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_scuola_giovani {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_formazione {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_eventi {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_emergenze {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_domande {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_docs {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_pnrr {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                    ... on node__homepage_minisito_it_alert {
                       fields {
                            slug
                       }
                       relationships {
                            field_sottodominio {
                                name
                            }
                       }
                       drupal_internal__nid
                    }
                }
                parent {
                  name
                  drupal_internal__tid
                }
              }
              drupal_internal__tid
            }
          }
        },
        menuFooter: allTaxonomyTermMenuFooter {
          edges {
            node {
              name
              weight
              relationships {
                field_link_menu {
                    ... on node__approfondimento {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__page {
                        fields {
                          slug
                        }
                        drupal_internal__nid
                    }
                    ... on node__elenco_glossario {
                        fields {
                            slug
                        }
                        drupal_internal__nid
                    }
                }
              }
              drupal_internal__tid
            }
          }
        }
  }
`;
